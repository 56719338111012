import * as Sentry from "@sentry/remix";
import mixpanel from "mixpanel-browser";

import { type User } from "#src/repos/deprecated/user/index.ts";
import {
  type CptLevel,
  type CptType,
} from "#src/routes/_dash.patients.mdm._index/types.ts";
import { isomorphicEnv } from "#src/utils/isomorphicEnv.ts";

type IdentifyUserArgs = {
  features: string[];
  user: Pick<User, "email" | "id" | "roles">;
};

export function identifyUser({ features, user }: IdentifyUserArgs) {
  if (!isomorphicEnv.MIXPANEL_ENABLED) {
    // eslint-disable-next-line no-console
    console.info("Mixpanel is disabled, skipping identify user");
    return;
  }

  try {
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.identify(user.id.toString());

    // Set these properties on the user's profile
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.people.set({
      email: user.email,
      features: features,
      roles: user.roles,
      userId: user.id,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.info("Failed to identify user for Mixpanel");
    Sentry.captureException(e);
  }
}

type CreateFlexTimeTaskEvent = {
  communityId?: number;
  patientId?: number;
  type: "createFlexTimeTask";
};

type NarBookVisitEvent = {
  bookingMode: string;
  patientId: number;
  type: "narBookVisit";
};

type OpenFlexTimeTaskModalEvent = {
  action: "add" | "edit";
  communityId?: number;
  location: "flexTaskTab" | "patientPanel" | "taskCard";
  patientId?: number;
  taskId?: number;
  type: "openFlexTimeTaskModal";
};

type PageViewEvent = {
  pathname: string;
  searchParams: { [key: string]: string };
  type: "pageView";
};

type PatientPanelColumnSortEvent = {
  column?: string;
  communityId: number;
  direction: "asc" | "desc";
  type: "patientPanelColumnSort";
};

type VisitAgendaItemClickEvent = {
  description: string;
  patientId: number;
  type: "visitAgendaItemClick";
};

type MdmTableSelectionsEvent = {
  dataLevel: CptLevel;
  level: CptLevel;
  patientId: number;
  problemsLevel: CptLevel;
  riskLevel: CptLevel;
  timeLevel: CptLevel;
  type: "mdmTableSelections";
};

type MdmTableRecommendationEvent = {
  code: number;
  level: CptLevel;
  patientId: number;
  rationaleType?: CptType;
  type: "mdmTableRecommendation";
};

type CptWorkflowCodeSelectionEvent = {
  code: number;
  patientId: number;
  type: "cptWorkflowCodeSelection";
  visitNoteId: bigint;
};

type TrackingEventArgs =
  | CptWorkflowCodeSelectionEvent
  | CreateFlexTimeTaskEvent
  | NarBookVisitEvent
  | OpenFlexTimeTaskModalEvent
  | PageViewEvent
  | PatientPanelColumnSortEvent
  | VisitAgendaItemClickEvent
  | MdmTableSelectionsEvent
  | MdmTableRecommendationEvent;

export function trackEvent(event: TrackingEventArgs) {
  if (!isomorphicEnv.MIXPANEL_ENABLED) {
    // eslint-disable-next-line no-console
    console.info("Mixpanel is disabled, skipping track event");
    return;
  }

  try {
    const { type, ...properties } = event;
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.track(type, properties);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.info("Failed to send event to Mixpanel");
    Sentry.captureException(e);
  }
}
